:root {
  --background-color: #ffffff;
  --background-code-color: #f2f2f2;
  --image-filter: grayscale(0%);
  --svg-inverse-filter: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--colors-foreground);
  text-decoration: underline;
  text-underline-position: under;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

img:not([src*=".svg"]) {
  filter: var(--image-filter);
}

article {
  font-size: 1.125rem;
}

article svg {
  filter: var(--svg-inverse-filter);
}

blockquote {
  margin: 0;
  padding: 0.125rem 1.5rem;
  border-left: 5px solid var(--colors-foreground);
  color: var(--colors-foreground);
  font-size: 1.25rem;
}

pre {
  border-radius: 5px;
  font-size: 0.8rem;
}

p > code,
li > code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 0.8rem;
  border-radius: 5px;
  background-color: var(--background-code-color);
}

ul {
  list-style: square;
}

li {
  margin-bottom: 5px;
}
